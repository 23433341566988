<template>
    <div class="datatable-pager datatable-paging-loaded">
        <ul class="datatable-pager-nav my-2 mb-sm-0">
            <li>
                <a title="首页"
                   class="datatable-pager-link datatable-pager-link-first"
                   data-page="1"
                   :class="[currentPage === 1?'datatable-pager-link-disabled':'']"
                   v-on:click="changePage($event, 1)"
                >
                    <i class="flaticon2-fast-back"></i>
                </a>
            </li>
            <li>
                <a title="上一页"
                   class="datatable-pager-link datatable-pager-link-prev"
                   :data-page="currentPage - 1"
                   :class="[currentPage === 1?'datatable-pager-link-disabled':'']"
                   v-on:click="changePage($event, currentPage - 1)"
                >
                    <i class="flaticon2-back"></i>
                </a>
            </li>

            <template v-if="pageCount > pageBtnsMax">
                <template v-if="currentPage < pageBtnsMax">
                    <li v-for="(index) in pageBtnsMax" v-bind:key="index">
                        <a class="datatable-pager-link datatable-pager-link-number"
                           :data-page="index" :title="index"
                           :class="[index === currentPage?'datatable-pager-link-active':'']"
                           v-on:click="changePage($event, index)"
                        >
                            {{index}}
                        </a>
                    </li>
                </template>
                <template v-else-if="currentPage > pageCount - pageBtnsMax">
                    <li v-for="(index) in pageBtnsMax" v-bind:key="pageCount - pageBtnsMax + index">
                        <a class="datatable-pager-link datatable-pager-link-number"
                           :data-page="pageCount - pageBtnsMax + index" :title="pageCount - pageBtnsMax + index"
                           :class="[(pageCount - pageBtnsMax + index) === currentPage?'datatable-pager-link-active':'']"
                           v-on:click="changePage($event, pageCount - pageBtnsMax + index)"
                        >
                            {{pageCount - pageBtnsMax + index}}
                        </a>
                    </li>
                </template>
                <template v-else>
                    <li v-for="index in halfPageBtnsMax" v-bind:key="currentPage - halfPageBtnsMax + index - 1">
                        <a class="datatable-pager-link datatable-pager-link-number"
                           :data-page="currentPage - halfPageBtnsMax + index - 1"
                           :title="currentPage - halfPageBtnsMax + index - 1"
                           v-on:click="changePage($event, currentPage - halfPageBtnsMax + index - 1)"
                        >
                            {{currentPage - halfPageBtnsMax + index - 1}}
                        </a>
                    </li>
                    <li>
                        <a class="datatable-pager-link datatable-pager-link-number datatable-pager-link-active"
                           :data-page="currentPage"
                           :title="currentPage"
                           v-on:click="changePage($event, currentPage)"
                        >
                            {{currentPage}}
                        </a>
                    </li>
                    <li v-for="index in halfPageBtnsMax" v-bind:key="currentPage + index">
                        <a class="datatable-pager-link datatable-pager-link-number"
                           :data-page="currentPage + index"
                           :title="currentPage + index"
                           v-on:click="changePage($event, currentPage + index)"
                        >
                            {{currentPage + index}}
                        </a>
                    </li>
                </template>

            </template>
            <template v-else>
                <li v-for="(index) in pageCount" v-bind:key="index">
                    <a class="datatable-pager-link datatable-pager-link-number"
                       :data-page="index" :title="index"
                       :class="[index === currentPage?'datatable-pager-link-active':'']"
                       v-on:click="changePage($event, index)"
                    >
                        {{index}}
                    </a>
                </li>
            </template>

            <li>
                <a title="下一页"
                   class="datatable-pager-link datatable-pager-link-next"
                   :data-page="currentPage + 1"
                   :class="[currentPage === pageCount?'datatable-pager-link-disabled':'']"
                   v-on:click="changePage($event, currentPage + 1)"
                >
                    <i class="flaticon2-next"></i>
                </a>
            </li>
            <li>
                <a title="末页"
                   class="datatable-pager-link datatable-pager-link-last"
                   :data-page="pageCount"
                   :class="[currentPage === pageCount?'datatable-pager-link-disabled':'']"
                   v-on:click="changePage($event, pageCount)"
                >
                    <i class="flaticon2-fast-next"></i>
                </a>
            </li>
        </ul>

        <div class="datatable-pager-info my-2 mb-sm-0">
            <select class="form-control" style="width:60px;margin-right:10px;" v-model="pageSizeSelect" autocomplete="off" v-on:change="changePageSize()">
                <option value=5>5</option>
                <option value="8">8</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            <span class="datatable-pager-detail">
                Showing {{pageSize * (currentPage - 1) + 1}} - {{pageSize * (currentPage)}} of {{total}}
            </span>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Pagination",
        props: {
            pageCount: Number,
            pageSize: Number,
            total: Number,
            pageBtnsMax: Number
        },
        data() {
            return {
                currentPage: 1,
                halfPageBtnsMax: parseInt(this.pageBtnsMax/2),
                pageSizeSelect: this.pageSize
            }
        },
        methods: {
            changePage(event, page) {
                event.preventDefault();
                if(page <= 0){
                    page = 1;
                }
                if(page > this.pageCount){
                    page = this.pageCount;
                }
                this.currentPage = page;
                this.$emit('pagechangeHandler',  this.currentPage, this.pageSize);
            },
            changePageSize() {
                this.$emit('pagechangeHandler', this.currentPage, parseInt(this.pageSizeSelect));
            }
        }
    }
</script>
