<template>
    <!--begin::Table-->
    <div class="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" style="overflow-x: auto;">
        <template v-if="list && list.length > 0">
            <table class="table table-head-custom table-vertical-center">
                <thead>
                    <tr class="text-left">

                        <th v-if="showChildren" class="pl-0" style="width: 20px;"></th>

                        <!-- select -->
                        <th v-if="showSelect" class="pl-0" style="width: 30px;">
                            <label class="checkbox checkbox-lg checkbox-single mr-2">
                                <input type="checkbox" @input="setCheck($event.target.checked)"/>
                                <span></span>
                            </label>
                        </th>

                        <th v-for="(item) in column"
                            v-bind:key="item.field"
                        >
                            <div :style="{width:item.width?item.width + 'px':'100px'}">{{item.title}}</div>
                        </th>
                    </tr>
                </thead>
                    <TableItem
                            v-bind:list="list"
                            v-bind:column="column"
                            v-bind:showChildren="showChildren"
                            v-bind:openAllChildren = "openAllChildren"
                            v-bind:showSelect="showSelect"
                            v-bind:selecled="checked"
                            v-bind:parentId="0"
                            @operateHandler="operateHandler"
                    ></TableItem>
            </table>

            <!-- page -->
            <template v-if="showPagination">
                <Pagination
                    v-bind:pageSize="pageSize"
                    v-bind:pageCount="pageCount"
                    v-bind:total="total"
                    v-bind:pageBtnsMax="5"
                    @pagechangeHandler="pagechangeHandler"
                >
                </Pagination>
            </template>
            <!-- page -->
        </template>
        <template v-else>
            <div class="nodata" style="background: url(media/common/nodata.png) center no-repeat;background-size:contain;height:280px;margin: 65px 0px;"></div>
        </template>
    </div>
    <!--end::Table-->
</template>
<script>

    import Pagination from "@/view/content/pagination/Pagination.vue";
    import TableItem from "@/view/content/tables/TableItem.vue";

    export default {
        name: "Datatable",
        props: {
            list: Array,
            column: Array,
            showChildren: Boolean,
            openAllChildren: Boolean,
            showSelect: Boolean,
            showPagination: Boolean,
            pageSize: Number,
            pageCount: Number,
            total: Number
        },
        data() {
            return {
                checked: false,
            };
        },
        components: {
            Pagination,
            TableItem
        },
        methods: {
            setCheck(check) {

                if (check) {
                    this.checked = check;
                } else {
                    this.checked = false;
                }
            },
            operateHandler(clazz, row) {
                this.$emit('operateHandler', clazz, row);
            },
            pagechangeHandler(page, pageSize) {
                this.$emit('pagechangeHandler', page, pageSize);
            }
        }
    };
</script>
