<template>
    <tbody :data-id="parentId">
        <template v-for="(item, i) in list">
            <tr v-bind:key="item.id" :data-index="i">

                <td v-if="showSelect" class="pl-0 py-6" style="width: 20px;">
                    <label class="checkbox checkbox-lg checkbox-single">
                        <input type="checkbox" :value="item.id" :checked="checked"/>
                        <span></span>
                    </label>
                </td>

                <td v-if="showChildren" style="width: 30px">
                    <a v-if="item.children" class="datatable-toggle-detail" href="javascript:;" v-on:click="showChildrenTable($event, item.id)">
                        <template v-if="openAllChildren">
                            <i class="fa fa-caret-down"></i>
                        </template>
                        <template v-else>
                            <i class="fa fa-caret-right"></i>
                        </template>
                    </a>
                </td>
                <template v-for="(c) in column">
                    <td v-bind:key="c.field">
                        <template v-if="c.formatter">
                            <div v-if="c.field == 'operate'"
                                 v-html="c.formatter(item)"
                                 v-on:click="operateClickEvent($event, item)"
                                 :style="{width:c.width?c.width + 'px':'100px'}">
                            </div>
                            <div v-else
                                 v-html="c.formatter(item)"
                                 v-on:click="operateClickEvent($event, item)"
                                 :style="{width:c.width?c.width + 'px':'100px'}"></div>
                        </template>
                        <template v-else>
                            <div :style="{width:c.width?c.width + 'px':'100px'}">{{item[c.field]}}</div>
                        </template>
                    </td>
                </template>
            </tr>
            <template  v-if="showChildren && item.children && item.children.length > 0">
                <tr v-bind:key="'children' + item.id" :ref="'children' + item.id" :class="{'children-hide-view':!openAllChildren}">
                    <td :colspan="column.length + (showChildren?1:0) + (showSelect?1:0)" style="padding: 0;margin:0px;">
                        <div class="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                            <table class="table table-head-custom table-vertical-center">
                                <TableItem
                                        v-bind:list="item.children"
                                        v-bind:column="column"
                                        v-bind:showChildren="showChildren"
                                        v-bind:openAllChildren = "openAllChildren"
                                        v-bind:showSelect="showSelect"
                                        v-bind:parentId="item.id"
                                        @operateHandler="operateHandler"
                                ></TableItem>
                            </table>
                        </div>
                    </td>
                </tr>
            </template>
        </template>
    </tbody>
</template>
<style>
    .children-hide-view{
        display: none;
    }
</style>
<script>
    export default {
        name: "TableItem",
        props: {
            list: Array,
            column: Array,
            showChildren: Boolean,
            openAllChildren: Boolean,
            showSelect: Boolean,
            selecled: Boolean,
            parentId: Number
        },
        created:function() {

        },
        watch: {
            selecled: function(){
                this.checked = this.selecled;
            }
        },
        data() {
            return {
                checked: this.selecled,
            };
        },
        methods: {
            operateClickEvent(event, row) {
                let tar = event.target;
                let clazz = tar.classList.value;
                if(clazz.indexOf('noevent') > -1) {
                } else{
                    event.preventDefault();
                    this.$emit('operateHandler', clazz, row);
                }
            },
            operateHandler(clazz, row) {
                this.$emit('operateHandler', clazz, row);
            },
            showChildrenTable(event, id) {
                event.preventDefault();
                let tar = event.target;
                let clazz = tar.classList.value;
                this.$nextTick(function () {
                    const children = this.$refs['children' + id][0];
                    if(clazz == 'fa fa-caret-right'){
                        //显示子表
                        tar.className  = 'fa fa-caret-down';
                        children.classList.remove("children-hide-view");
                    } else {
                        //隐藏子表
                        tar.className  = 'fa fa-caret-right';
                        children.classList.add("children-hide-view");
                    }
                });
            }
        }
    }
</script>
